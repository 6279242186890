import { ChangeEvent } from 'react'
import clsx from 'clsx'

export const InputStyle = {
  fieldSimple:
    'focus:shadow-outline block h-full w-full appearance-none border-none bg-transparent py-2 px-4 text-gray-700 transition-all focus:shadow-transparent focus:outline-none',
  info: 'absolute text-xs tracking-wider antialiased pt-1',
  helper: 'fade-in text-gray-400 text-xs tracking-wider antialiased',
  error:
    'fade-in font-medium text-error-400 text-xs tracking-wider antialiased',
  input:
    'block h-10 w-full rounded-sm border-0 border-b-2 border-gray-200 focus:border-gray-400 text-gray-700 transition-colors focus:border-black',
  label: 'block pt-4 pb-2 text-lg font-semibold tracking-wide text-gray-900',
  fieldWrap: 'relative',
  required: 'ml-1 font-bold text-red-550'
} as const

export type InputProps = {
  label?: string | JSX.Element
  name: string
  placeholder?: string
  type?: string
  helperText?: string
  className?: string
  inputClassName?: string
  defaultValue?: string
  labelStyle?: string
  disabled?: boolean
  maxlength?: number
  required?: boolean
  value?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void
}

const Input = ({
  label,
  name,
  placeholder,
  type = 'text',
  disabled,
  maxlength,
  value,
  required,
  className,
  inputClassName,
  defaultValue,
  helperText,
  onChange,
  onBlur
}: InputProps) =>
  label === '' ? (
    <input
      className={clsx(InputStyle.fieldSimple, className)}
      name={name}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  ) : (
    <div className={clsx(InputStyle.fieldWrap, className)}>
      <label className={InputStyle.label} htmlFor={name}>
        {label}
        {required && <span className={InputStyle.required}>*</span>}
      </label>
      <input
        defaultValue={defaultValue}
        value={value}
        className={clsx(InputStyle.input, inputClassName)}
        name={name}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        onBlur={onBlur}
        maxLength={maxlength}
        required={required}
        onChange={onChange}
      />
      <div className={InputStyle.info}>
        <div className={InputStyle.helper}>{helperText}</div>
      </div>
    </div>
  )

export default Input
