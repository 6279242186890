import clsx from 'clsx'

import { ICountryOption } from 'components/selects/CountryAutocomplete'
import { InputElementProps } from 'components/selects/types'

import useInput from './useInput'

const CountryInput = <Item extends ICountryOption>({
  selectedItem,
  onChange,
  itemToString,
  onClear,
  value,
  className,
  ...rest
}: InputElementProps<Item>) => {
  const { onInputChange } = useInput({
    onChange,
    itemToString,
    value,
    selectedItem
  })

  return (
    <>
      {!!selectedItem?.code && (
        <span
          className={clsx(
            'mr-2  h-4 w-4 flex-none',
            'fp fp-rounded',
            selectedItem?.code?.toLowerCase()
          )}
        ></span>
      )}
      <input
        onChange={onInputChange}
        value={value}
        className={clsx('w-full cursor-pointer', className)}
        {...rest}
      />
    </>
  )
}
export default CountryInput
