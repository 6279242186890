import Autocomplete from '../Autocomplete'
import { DraggableSelectedInput } from '../components'

import { IMultiListItemsAutocomplete } from './types'
import useMultiListItemSelect from './useMultiListItemSelect'

const MultiListItemSelect = ({
  selectedItems,
  onChange,
  limit,
  isDraggable = true,
  type,
  name,
  industriesFilterIds,
  ...props
}: IMultiListItemsAutocomplete & {
  industriesFilterIds?: string[] | undefined | null
}) => {
  const { options, filterFn, isDesktop, isLoading } = useMultiListItemSelect({
    selectedItems,
    type,
    industriesFilterIds
  })

  return (
    <Autocomplete
      onSelect={(item) => {
        if (!limit || selectedItems.length < limit) {
          item && onChange?.((prev) => [...prev, item])
        }
        return
      }}
      items={options ?? []}
      isLoading={isLoading}
      disabled={!!(limit && selectedItems.length >= limit)}
      filterFn={filterFn}
      itemToString={(x) => x?.value ?? ''}
      inputAs={(props) => (
        <DraggableSelectedInput
          {...props}
          isDraggable={isDesktop && isDraggable}
          onDragged={onChange}
          onRemove={(item) =>
            onChange((prev) => prev.filter((x) => x.id !== item.id))
          }
          selectedItems={selectedItems}
          limit={limit}
        />
      )}
      onClean={() => onChange([])}
      isMulti
      {...props}
    />
  )
}
export default MultiListItemSelect
