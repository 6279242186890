import { IsItemSelected, ItemToStringFn } from './types'

export const itemToStringDefault: ItemToStringFn<unknown> = (item) =>
  String(item ?? '')

export const isItemSelectedDefault: IsItemSelected<unknown> = (
  selectedItem,
  item
) => selectedItem === item

export const currentSelectedItem = <Item>(
  items: Item[],
  selectedItem: Item | null | undefined,
  isItemSelectedFn: IsItemSelected<Item>
) => {
  if (selectedItem == null) {
    return null
  }

  const index = items.findIndex((item) => isItemSelectedFn(selectedItem, item))
  return index === -1 ? null : index
}
