import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useField } from 'formik'

import { languageLevelDict } from 'data/languageLevels'
import { getCurrentLocaleCode } from 'utils/local.util'

import { Autocomplete } from '../index'
import { ILanguageAutocompleteProps } from '../LanguageAutocomplete/types'

const LanguageLevelAutocomplete = ({
  name,
  ...props
}: ILanguageAutocompleteProps) => {
  const { locale } = useRouter()
  const [field, , { setValue, setTouched }] = useField(name)

  const options = useMemo(() => {
    return languageLevelDict[getCurrentLocaleCode(locale)]
  }, [locale])
  const selectedValue = useMemo(
    () => options.find((x) => x.value === field.value) ?? null,
    [field.value, options]
  )
  return (
    <Autocomplete
      name={name}
      items={options}
      selectedItem={selectedValue}
      onSelect={(value) => {
        setValue(value?.value ?? '')
      }}
      onClean={() => setTouched(true, false)}
      onChange={() => setTouched(true, false)}
      itemToString={(x) => x?.label ?? ''}
      {...props}
    />
  )
}

export default LanguageLevelAutocomplete
