import { useCallback, useEffect, useState } from 'react'

import { isSSR } from 'utils'

const useMediaQuery = (query: string) => {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (!isSSR()) {
      return window.matchMedia(query).matches
    }
    return false
  }
  const [matches, setMatches] = useState<boolean>()

  const handleChange = useCallback(() => {
    setMatches(getMatches(query))
  }, [query])

  useEffect(() => {
    setMatches(getMatches(query))
    const matchMedia = window.matchMedia(query)

    matchMedia.addEventListener('change', handleChange)
    return () => {
      matchMedia.removeEventListener('change', handleChange)
    }
  }, [handleChange, query])

  return matches
}
export default useMediaQuery
