import React, { useCallback, useRef } from 'react'
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableElementProps,
  SortEndHandler
} from 'react-sortable-hoc'
import clsx from 'clsx'

import ItemSkill from './Item'
import {
  IDraggableItem,
  IDraggableSelectedInput,
  IDraggableSelectedItem
} from './types'

type ISortableItem = <Item extends IDraggableSelectedItem>(
  props: SortableElementProps & IDraggableItem<Item>
) => JSX.Element

const SortableItem = SortableElement(
  <Item extends IDraggableSelectedItem>(props: IDraggableItem<Item>) => {
    return <ItemSkill {...props} />
  }
) as unknown as ISortableItem

const List = <Item extends IDraggableSelectedItem>({
  selectedItems,
  placeholder,
  itemToString,
  onRemove,
  onChange,
  onDragged,
  onClear,
  selectedItem,
  className,
  isDraggable,
  value,
  limit,
  ...rest
}: IDraggableSelectedInput<Item>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value)
    },
    [onChange]
  )
  return (
    <div
      className="flex flex-1 flex-wrap gap-2 overflow-hidden"
      onClick={() => {
        inputRef.current?.focus()
      }}
    >
      {selectedItems.map((item, index) => (
        <SortableItem
          disabled={!isDraggable}
          key={item.id}
          className="relative z-[200] items-center rounded-2xl border-[2px] border-gray-200 bg-gray-200 px-2 pt-[1px] text-sm text-gray-700 "
          item={item}
          label={itemToString?.(item) ?? 'itemToString not provided'}
          onRemove={onRemove}
          index={index}
        />
      ))}

      <input
        ref={inputRef}
        value={value}
        size={15}
        className={clsx(
          selectedItems.length === limit ? 'hidden' : 'flex-1',
          className
        )}
        onChange={onInputChange}
        placeholder={selectedItems.length ? '' : placeholder}
        {...rest}
      />
    </div>
  )
}

const SortableList = SortableContainer(
  <Item extends IDraggableSelectedItem>(
    props: IDraggableSelectedInput<Item>
  ) => {
    return <List {...props} />
  }
)

const DraggableSelectedInput = <Item extends IDraggableSelectedItem>(
  props: IDraggableSelectedInput<Item>
) => {
  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    props.onDragged((prev) => arrayMove(prev, oldIndex, newIndex))
  }

  return (
    <SortableList
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      helperClass="z-50"
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      {...props}
    />
  )
}
export default DraggableSelectedInput
