import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import {
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions
} from '@floating-ui/react'
import { ISkill, ISkillDto } from 'data-transfers/dto'
import { useField } from 'formik'

import { useSkills } from 'hooks/useSkills'

import { ISkillsSelectProps } from './types'

const useSkillsSelect = ({
  name,
  onChangeValue
}: Pick<ISkillsSelectProps, 'name' | 'onChangeValue'>) => {
  const [field, , { setValue }] = useField<ISkill[] | null>(name)
  const { t } = useTranslation(['inputs'])
  const { locale } = useRouter()

  const [inputValue, setInputValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const { data: skills, isLoading } = useSkills({ languageCode: locale })

  const selectedItems = useMemo(() => {
    return (
      field.value?.map<ISkillDto>(({ skillId, skill }) => ({
        id: skillId ?? '',
        value: skill ?? ''
      })) ?? []
    )
  }, [field.value])

  const length = useMemo(() => selectedItems.length > 0, [selectedItems])

  const options = useMemo(() => {
    return (
      skills?.filter(
        ({ id, value }) =>
          !selectedItems.some((selectedItem) => selectedItem.id === id) &&
          value.toLowerCase().includes(inputValue.toLowerCase())
      ) ?? []
    )
  }, [inputValue, selectedItems, skills])

  const onChange = useCallback<Dispatch<SetStateAction<ISkillDto[]>>>(
    (setStateAction) => {
      const items =
        typeof setStateAction === 'function'
          ? setStateAction(selectedItems)
          : setStateAction

      setValue(
        items.map<ISkill>(
          (item) => ({
            skillId: item.id,
            skill: item.value
          }),
          true
        )
      )

      onChangeValue?.()
    },
    [onChangeValue, selectedItems, setValue]
  )

  const onSelect = useCallback(
    (e: SetStateAction<ISkillDto[]>) => {
      setInputValue('')
      return onChange(e)
    },
    [onChange]
  )

  const onRemove = useCallback(
    (id: string) => onChange((prev) => prev.filter((x) => x.id !== id)),
    [onChange]
  )

  const onChangeInput = useCallback((text: string) => setInputValue(text), [])

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(4),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: '600px'
          })
        },
        padding: 0
      })
    ]
  })

  const click = useClick(context, { keyboardHandlers: false })
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss
  ])

  return {
    t,
    length,
    options,
    isLoading,
    selectedItems,
    isOpen,
    setIsOpen,
    onRemove,
    onChangeInput,
    inputValue,
    onSelect,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps
  }
}

export default useSkillsSelect
