import { z } from 'zod'

const ListItemSchema = z.object({
  id: z.string().uuid().optional().nullable(),
  value: z.string(),
  label: z.string().optional(),
  languageCode: z.string().default('en').optional().nullable(),
  order: z.number().optional()
})

export const ListIndustrySchema = z.object({
  id: z.string().uuid(),
  value: z.string().optional()
})

export default ListItemSchema
