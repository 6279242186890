import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useField } from 'formik'

import { useList } from 'hooks/useList'

import { Autocomplete } from '../index'

import { CreatableAutocompleteProps } from './types'

const CreatableAutocomplete = ({
  type,
  lang,
  name,
  positionName,
  isCreatable,
  onChange: onChangeFn,
  onBlur,
  ...props
}: CreatableAutocompleteProps) => {
  const { locale: languageCode } = useRouter()
  const [{ value }, , { setValue }] = useField(name.value)
  const [{ value: id }, , { setValue: setId }] = useField(name.id)
  const [{ value: position }] = useField(positionName ?? '')
  const [, , { setValue: setCustomEnglishTitle }] =
    useField('customEnglishText')
  const [, , { setValue: setCustomLithuanianTitle }] = useField(
    'customLithuanianText'
  )

  const { data, isLoading } = useList({
    languageCode: lang ?? languageCode,
    type
  })

  const selectedValue = useMemo(() => {
    return data?.find((item) => item.id === id)
  }, [data, id])

  return (
    <Autocomplete
      name={positionName ?? isCreatable ? name.value : name.id}
      items={data ?? []}
      defaultValue={
        isCreatable && positionName ? position : isCreatable && value
      }
      selectedItem={selectedValue}
      onSelect={(values) => {
        if (!onChangeFn) {
          setValue(values?.value || '')
          setId(values?.id || null)
        }

        onChangeFn?.({
          value: values?.value || '',
          id: values?.id || undefined
        })
      }}
      onChange={
        isCreatable
          ? (value) => {
              if (!onChangeFn) {
                setValue(value || '')
                setId(null)
              }

              onChangeFn?.({ value: value || '', id: undefined })
              lang === 'en'
                ? setCustomEnglishTitle(value)
                : setCustomLithuanianTitle(value)
            }
          : undefined
      }
      onBlur={onBlur}
      itemToString={(x) => x?.value ?? ''}
      isLoading={isLoading}
      {...props}
    />
  )
}

export default CreatableAutocomplete
