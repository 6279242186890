import { useTranslation } from 'next-i18next'
import clsx from 'clsx'

import { ISelectProps } from '../types'

const Select = ({
  items,
  onSelect,
  isLoading,
  disabled = false
}: ISelectProps) => {
  const { t } = useTranslation(['inputs', 'actions'])
  return (
    <div className={'text-md max-h-[300px] overflow-auto'}>
      {items.length ? (
        items.map((item, index) => (
          <div
            key={index}
            className={clsx('px-4 py-3', !disabled && 'hover:bg-gray-200')}
            onClick={
              !disabled ? () => onSelect((prev) => [...prev, item]) : undefined
            }
          >
            {item.value}
          </div>
        ))
      ) : (
        <div className="select-none px-4 py-3 text-center text-gray-500 ">
          {isLoading && `${t('loading', { ns: 'actions' })}...`}
          {!isLoading && t('skillsMultiple.noOptions', { ns: 'inputs' })}
        </div>
      )}
    </div>
  )
}

export default Select
