import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import clsx from 'clsx'

import { ButtonIcon } from 'components/buttons'
import { TextTooltip } from 'components/Tooltip'
import { IconChevron, IconClose } from 'icons'

import styles from 'styles/Scrollbar.module.scss'

import { Input, Select, SelectedItems } from './components'
import { ISkillsSelectProps } from './types'
import useSkillsSelect from './useSkillsSelect'
const SkillsSelect = ({
  placeholder,
  tooltip,
  ...props
}: ISkillsSelectProps) => {
  const {
    t,
    length,
    options,
    isLoading,
    isOpen,
    onRemove,
    selectedItems,
    inputValue,
    onSelect,
    onChangeInput,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps
  } = useSkillsSelect(props)

  const SkillSelectContent = (
    <div className={'relative z-0'}>
      <div
        {...getReferenceProps({
          ref: refs.setReference,
          className: clsx(
            'flex h-10 items-center justify-between border-b-2 border-b-gray-300 bg-white pr-4 pl-2 cursor-pointer',
            isOpen ? 'border-gray-400' : 'border-gray-300'
          )
        })}
      >
        {length ? (
          <SelectedItems
            selectedItems={selectedItems}
            onRemove={onRemove}
            hide
          />
        ) : (
          <div className={'select-none text-gray-500'}>{placeholder}</div>
        )}

        {length ? (
          <ButtonIcon
            onClick={(e) => {
              onSelect(() => [])
              e.stopPropagation()
            }}
            icon={() => <IconClose className="inline" />}
            className="inline-flex h-5 w-5 rounded-[50%] align-middle"
          />
        ) : (
          <IconChevron
            size={16}
            className={clsx(
              'transition-all duration-300 ease-out',
              isOpen ? 'text-gray-500' : 'rotate-180 text-gray-300'
            )}
          />
        )}
      </div>

      <FloatingPortal>
        {isOpen && (
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                className: clsx(
                  'overflow-y-auto rounded border border-gray-300 bg-white py-2 shadow-lg z-[200]',
                  styles.scrollbar
                ),
                style: floatingStyles
              })}
            >
              <Input
                placeholder={
                  tooltip ??
                  t('skillsMultiple.inputPlaceholder', {
                    ns: 'inputs'
                  })
                }
                onChange={onChangeInput}
                value={inputValue}
                disabled={!!tooltip}
              />

              {length && (
                <div
                  className={
                    'max-h-[200px] overflow-y-auto border-b-2 border-b-gray-200 p-4'
                  }
                >
                  <h5 className={'text-md mb-2'}>
                    {t('skillsMultiple.selectedSkills', {
                      ns: 'inputs',
                      data: selectedItems.length
                    })}
                  </h5>

                  <SelectedItems
                    selectedItems={selectedItems}
                    onRemove={onRemove}
                  />
                </div>
              )}

              <Select
                items={options}
                onSelect={onSelect}
                isLoading={isLoading}
                disabled={!!tooltip}
              />
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </div>
  )

  return tooltip ? (
    <TextTooltip tooltipContent={tooltip} placement={'top'}>
      {SkillSelectContent}
    </TextTooltip>
  ) : (
    SkillSelectContent
  )
}

export default SkillsSelect
