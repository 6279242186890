import { forwardRef, HTMLProps } from 'react'
import { useId } from '@floating-ui/react'
import clsx from 'clsx'

import { ICountryOption } from 'components/selects/CountryAutocomplete'
import { IDefaultItemProps } from 'components/selects/types'

const CountrySelectItem = forwardRef<
  HTMLDivElement,
  IDefaultItemProps<ICountryOption> & HTMLProps<HTMLDivElement>
>(function DefaultItem({ children, active, selected, item, ...rest }, ref) {
  const id = useId()
  return (
    <div
      ref={ref}
      role="option"
      id={id}
      aria-selected={active}
      className={clsx(
        active && 'bg-gray-200',
        selected && 'font-bold',
        'mx-2 flex flex-col rounded py-2 px-3'
      )}
      {...rest}
    >
      <span className="fade-in">
        {item?.code && (
          <span
            className={clsx(
              'mr-2 mb-1',
              'fp fp-rounded',
              item?.code?.toLowerCase()
            )}
          ></span>
        )}
        {children}
      </span>
    </div>
  )
})

export default CountrySelectItem
