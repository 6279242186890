import clsx from 'clsx'

import { IInputProps } from '../types'

const Input = ({
  placeholder,
  value,
  onChange,
  disabled = false
}: IInputProps) => {
  return (
    <input
      disabled={disabled}
      value={value}
      type={'text'}
      className={clsx(
        'w-full border-2 border-b-2 border-white focus:border-2 focus:border-b-2 focus:border-white',
        'border-b-gray-200 focus:border-b-gray-200'
      )}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value.trim())}
    />
  )
}

export default Input
