import { useRouter } from 'next/router'
import { forwardRef, useCallback, useMemo } from 'react'

import countries from 'data/countries'

import Autocomplete from '../Autocomplete'
import { IForwardRefAutocompleteProps } from '../Autocomplete/types'
import { CountryInput, CountrySelectItem } from '../components'

import { ICountryAutocomplete, ICountryOption } from './types'

const CountryAutocomplete = forwardRef<
  IForwardRefAutocompleteProps,
  ICountryAutocomplete
>(function CountryNameAutocomplete(
  { allowedCountries, selectedCountryName, selectedItem, ...props },
  ref
) {
  const { locale } = useRouter()

  const options = useMemo<ICountryOption[]>(() => {
    let items = countries
    if (allowedCountries) {
      items = countries.filter((country) =>
        allowedCountries.includes(country.name)
      )
    }

    return items.map(({ translate, ...option }) => ({
      ...option,
      label: translate[locale] ?? option.name
    }))
  }, [allowedCountries, locale])

  const filterFn = useCallback((item: ICountryOption, inputValue?: string) => {
    const lowerCasedInputValue = inputValue?.toLowerCase()

    return (
      !lowerCasedInputValue ||
      item.name.toLowerCase().includes(lowerCasedInputValue) ||
      item.label.toLowerCase().includes(lowerCasedInputValue)
    )
  }, [])
  const selectedItemInternal = useMemo(() => {
    return (
      selectedItem ??
      options.find((x) => x.name === selectedCountryName) ??
      null
    )
  }, [options, selectedCountryName, selectedItem])
  return (
    <Autocomplete
      ref={ref}
      items={options}
      filterFn={filterFn}
      itemToString={(x) => x?.label ?? ''}
      inputAs={CountryInput}
      optionAs={CountrySelectItem}
      selectedItem={selectedItemInternal}
      {...props}
    />
  )
})

export default CountryAutocomplete
