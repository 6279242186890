import { useRef } from 'react'
import { FloatingPortal } from '@floating-ui/react'

import { IconDotsAltR } from 'icons'

import { IContextMenuProps } from './types'
import useContextMenu from './useContextMenu'
const ContextMenu = ({ items }: IContextMenuProps) => {
  const { isOpen, refs, floatingStyles, getReferenceProps, getFloatingProps } =
    useContextMenu({ placement: 'bottom-end' })

  const ref = useRef(null)

  return (
    <>
      <div className={'relative'} ref={ref}>
        <div
          ref={refs.setReference}
          {...getReferenceProps({
            className:
              'flex w-full items-center cursor-pointer transition-colors duration-300 ease-out bg-white'
          })}
        >
          <div
            className={
              'action h-7 border-2 border-black opacity-0 transition-opacity duration-100 ease-out hover:border-red-300 hover:bg-red-300'
            }
          >
            <IconDotsAltR />
          </div>
        </div>
      </div>

      {isOpen && (
        <FloatingPortal root={ref}>
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              className:
                'overflow-y-auto rounded border border-gray-300 bg-white shadow-lg z-10 relative',
              style: floatingStyles
            })}
          >
            {items.map((item, index) => (
              <div
                key={index}
                onClick={item.clickItem}
                className={
                  'min-w-[100px] cursor-pointer px-4 py-2 text-sm font-semibold text-[#4D5562] hover:bg-gray-100'
                }
              >
                {item.label ?? ''}
              </div>
            ))}
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

export default ContextMenu
