import clsx from 'clsx'

import { InputElementProps } from 'components/selects/types'

import useInput from './useInput'

const DefaultInput = <Item,>({
  selectedItem,
  onChange,
  value,
  itemToString,
  onClear,
  className,
  ...props
}: InputElementProps<Item>) => {
  const { onInputChange } = useInput({
    onChange,
    itemToString,
    selectedItem,
    value
  })

  return (
    <input
      onChange={onInputChange}
      value={value}
      className={clsx('w-full cursor-pointer', className)}
      {...props}
    />
  )
}
export default DefaultInput
