const LanguageLevels = [
  { value: 'N', label: 'Native' },
  { value: 'A1', label: 'A1 – Beginner' },
  { value: 'A2', label: 'A2 – Novice' },
  { value: 'B1', label: 'B1 – Independent' },
  { value: 'B2', label: 'B2 – Intermediate' },
  { value: 'C1', label: 'C1 – Advanced' },
  { value: 'C2', label: 'C2 – Proficient' }
]

const LanguageLevelsLt = [
  { value: 'N', label: 'Gimtoji' },
  { value: 'A1', label: 'A1 – Pradedantysis' },
  { value: 'A2', label: 'A2 – Naujokas' },
  { value: 'B1', label: 'B1 – Nepriklausomas' },
  { value: 'B2', label: 'B2 – Vidutinis' },
  { value: 'C1', label: 'C1 – Aukštesnysis' },
  { value: 'C2', label: 'C2 – Profesionalas' }
]

export const languageLevelDict = {
  en: LanguageLevels,
  lt: LanguageLevelsLt
}
