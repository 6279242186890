import { useQuery } from '@tanstack/react-query'
import { ISkillDto } from 'data-transfers/dto'

import { publicClient } from 'utils'

export const getSkills = async ({
  languageCode = 'en'
}: {
  languageCode?: string
}) => {
  const { data } = await publicClient.get<ISkillDto[]>(`/skills`, {
    params: {
      languageCode,
      limit: 10000,
      skip: 0
    }
  })
  return data
}

export const useSkills = ({
  languageCode = 'en'
}: {
  languageCode?: string
}) => {
  return useQuery(
    ['skill_data', { languageCode }],
    async () => {
      const data = await getSkills({
        languageCode
      })
      return data
    },
    {
      keepPreviousData: true,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      onError: (err) => {
        console.error('Skill Fetch Failed', err)
      }
    }
  )
}
