import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useField } from 'formik'

import { languagesDict } from 'data/languages'
import { getCurrentLocaleCode } from 'utils/local.util'

import { CountryInput, CountrySelectItem } from '../components'
import { Autocomplete } from '../index'

import { ILanguageAutocompleteProps } from './types'

const LanguageAutocomplete = ({
  name,
  allowedLanguages,
  ...props
}: ILanguageAutocompleteProps) => {
  const { locale } = useRouter()
  const [field, , { setValue, setTouched }] = useField(name)
  const options = useMemo(() => {
    let result = languagesDict[getCurrentLocaleCode(locale)]
    if (allowedLanguages?.length) {
      result = result.filter((lang) => allowedLanguages.includes(lang.name))
    }

    return result.map((item) => ({
      ...item,
      language: item.name,
      value: item.name
    }))
  }, [allowedLanguages, locale])

  return (
    <Autocomplete
      name={name}
      items={options}
      selectedItem={options.find((x) => x.value === field.value) ?? null}
      onSelect={(value) => {
        setValue(value?.name)
      }}
      itemToString={(x) => x?.label ?? ''}
      inputAs={CountryInput}
      optionAs={CountrySelectItem}
      onClean={() => setTouched(true, false)}
      onChange={() => setTouched(true, false)}
      {...props}
    />
  )
}

export default LanguageAutocomplete
