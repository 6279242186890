import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import clsx from 'clsx'
import { ErrorMessage } from 'formik'

import { ButtonIcon } from 'components/buttons'
import { InputStyle } from 'components/Input'
import Spinner from 'components/Spinner'
import { IconChevron, IconClose } from 'icons'

import { getZodMessage } from 'utils/validation.util'

import styles from 'styles/Scrollbar.module.scss'

import { DefaultSelectItem } from '../components'
import { ItemElement } from '../types'
import { itemToStringDefault } from '../utils'

import { ISelectProps } from './types'
import useSelect from './useSelect'
const Select = <Item,>({
  items,
  required,
  label,
  placeholder,
  className,
  name,
  helperText,
  isLoading,
  selectorClassName,
  selectedItem,
  isClearable,
  optionAs: Item = DefaultSelectItem as ItemElement<Item>,
  itemToString = itemToStringDefault,
  ...rest
}: ISelectProps<Item>) => {
  const {
    isOpen,
    refs,
    context,
    floatingStyles,
    activeIndex,
    selectedIndex,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    onClear,
    t
  } = useSelect({ ...rest, itemToString, selectedItem, items })

  return (
    <>
      <div className={className}>
        <div>
          {label && (
            <label id="select-label" className={InputStyle.label}>
              {label}
              {required && (
                <span className="ml-1 font-bold text-red-550">*</span>
              )}
            </label>
          )}
          <div
            tabIndex={0}
            ref={refs.setReference}
            aria-labelledby="select-label"
            aria-autocomplete="none"
            {...getReferenceProps({
              className: clsx(
                'flex w-full items-center border-b-2 cursor-pointer p-2 pb-1 pr-4  transition-colors duration-300 ease-out',
                isOpen ? 'border-gray-400' : 'border-gray-300',
                selectorClassName
              )
            })}
          >
            <span className="flex-1 bg-transparent">
              {selectedItem != null ? itemToString(selectedItem) : placeholder}
            </span>
            <Spinner
              isVisible={isLoading}
              size={24}
              className="pointer-events-none"
            />
            {isClearable && (
              <ButtonIcon
                onClick={onClear}
                icon={IconClose}
                className="h-5 w-5"
                rounded
              />
            )}
            <div
              className={clsx(
                'ml-1 mr-2 h-6  border-l-2  transition-colors duration-300 ease-out ',
                isOpen ? 'border-gray-400' : 'border-gray-300'
              )}
            ></div>
            <IconChevron
              className={clsx(
                'h-4 w-4 transition-all duration-300 ease-out',
                isOpen ? 'text-gray-500' : 'rotate-180 text-gray-300'
              )}
            />
          </div>
        </div>
        <div className={InputStyle.info}>
          {helperText && <div className={InputStyle.helper}>{helperText}</div>}
          {name && (
            <ErrorMessage
              name={name}
              render={(msg) => (
                <div className="fade-in text-medium text-error-400">
                  {getZodMessage(msg, t(msg, { ns: 'zod' }))}
                </div>
              )}
            />
          )}
        </div>
      </div>

      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                className: clsx(
                  'overflow-y-auto rounded border border-gray-300 bg-white py-2 shadow-lg',
                  styles.scrollbar
                ),
                style: floatingStyles
              })}
            >
              {items.map((item, index) => (
                <Item
                  key={index}
                  tabIndex={index === activeIndex ? 0 : -1}
                  active={activeIndex === index}
                  selected={selectedIndex === index}
                  aria-selected={
                    index === selectedIndex && index === activeIndex
                  }
                  item={item}
                  {...getItemProps(item, index)}
                >
                  {itemToString(item)}
                </Item>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}

export default Select
