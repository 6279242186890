import clsx from 'clsx'

import { ButtonIcon } from 'components/buttons'
import { IconClose } from 'icons'

import { IDraggableItem, IDraggableSelectedItem } from './types'

const Item = <Item extends IDraggableSelectedItem>({
  onRemove,
  item,
  label,
  className
}: IDraggableItem<Item>) => {
  return (
    <div className={clsx(className, 'select-none')}>
      <span className="mx-1 ">{label}</span>

      <ButtonIcon
        onClick={(e) => {
          onRemove(item)
          e.stopPropagation()
        }}
        icon={() => <IconClose className="inline" />}
        className="inline-flex h-5 w-5 rounded-[50%] align-middle"
      />
    </div>
  )
}

export default Item
