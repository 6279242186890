import { useQuery } from '@tanstack/react-query'
import { IListItem } from 'data-transfers/dto'

import listItemValidator, {
  ListItemType
} from 'validators/new/listItem.validator'
import { publicClient } from 'utils'

import { ILocale } from '../types/app'

export type ListType =
  | 'ContractType'
  | 'Industry'
  | 'Position'
  | 'PositionLevel'
  | 'RemoteWorkType'
  | 'TransportBenefit'
  | 'BusinessFunction'
  | 'TrainingBudget'
  | 'EducationOrganisation'
  | 'EducationDegree'
  | 'FieldOfStudy'

export const getList = async ({
  languageCode = 'en',
  type
}: {
  languageCode?: string
  type: ListType
}) => {
  const response = await publicClient.get(`/lists`, {
    params: {
      type,
      languageCode,
      limit: 10000,
      skip: 0
    }
  })
  if (response.status >= 200 && response.status < 300) {
    return response.data as IListItem[]
  }
  return null
}

export const useList = ({
  languageCode = 'en',
  type,
  select
}: {
  languageCode?: ILocale | string
  type: ListType
  select?: (data: ListItemType[] | null) => ListItemType[] | null
}) => {
  return useQuery(
    [`list_${type}`, { languageCode, type }],
    async () => {
      const data = await getList({
        languageCode,
        type
      })
      const parsed = listItemValidator.array().safeParse(data)
      if (parsed.success) {
        return parsed.data
      }
      throw new Error('Invalid data')
    },
    {
      select,
      keepPreviousData: true,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      onError: (err) => {
        console.error('Skill Fetch Failed', err)
      }
    }
  )
}
