import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { IListItem } from 'data-transfers/dto'

import { useList } from 'hooks/useList'
import useMediaQuery from 'hooks/useMediaQuery'

import { minWidth } from 'utils'
import { RESPONSIVE } from 'constants/responsive.constant'

import { IMultiListItemsAutocomplete } from './types'

const useMultiListItemSelect = ({
  selectedItems,
  type,
  industriesFilterIds
}: Pick<IMultiListItemsAutocomplete, 'selectedItems' | 'type'> & {
  industriesFilterIds?: string[] | undefined | null
}) => {
  const isDesktop = useMediaQuery(minWidth(RESPONSIVE.lg))
  const { locale: languageCode } = useRouter()
  const { data: items, isLoading } = useList({
    languageCode,
    type,
    select: (data) => {
      if (!industriesFilterIds?.length) {
        return data
      }
      return (
        data?.filter((item) => !industriesFilterIds.includes(item?.id ?? '')) ??
        []
      )
    }
  })

  const filterFn = useCallback((item: IListItem, inputValue?: string) => {
    const lowerCasedInputValue = inputValue?.toLowerCase()

    return (
      !lowerCasedInputValue ||
      item.value.toLowerCase().includes(lowerCasedInputValue)
    )
  }, [])

  const options = useMemo(
    () =>
      items?.filter(
        (({ id }) =>
          !selectedItems.some((selectedItem) => selectedItem.id === id)) ?? []
      ) as IListItem[],
    [selectedItems, items]
  )

  return { options, filterFn, isDesktop, isLoading }
}

export default useMultiListItemSelect
