import { ButtonIcon } from 'components/buttons'
import { IconClose } from 'icons'

import { IItemProps } from '../types'

const Item = ({ label, onRemove }: IItemProps) => (
  <div
    className={
      'w-fit select-none items-center rounded-2xl border-[2px] border-gray-200 bg-gray-200 px-2 pt-[1px] text-sm'
    }
  >
    <span className="mx-1">{label}</span>

    {onRemove && (
      <ButtonIcon
        onClick={() => onRemove?.()}
        icon={() => <IconClose className="inline" />}
        className="inline-flex h-5 w-5 rounded-[50%] align-middle"
      />
    )}
  </div>
)

export default Item
