import { useRef, useState } from 'react'
import {
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useFloating,
  useHover,
  useInteractions,
  useListNavigation,
  useRole
} from '@floating-ui/react'

import { IUseContextMenu } from './types'

const useContextMenu = ({ placement, mainAxis }: IUseContextMenu) => {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [shift(), offset(5), flip({ mainAxis })]
  })

  const listRef = useRef<Array<HTMLElement | null>>([])
  const hover = useHover(context, { handleClose: safePolygon(), delay: 100 })
  const role = useRole(context, { role: 'listbox' })
  const listNav = useListNavigation(context, { listRef, activeIndex: 0 })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    role,
    listNav,
    hover
  ])

  return {
    isOpen,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps
  }
}

export default useContextMenu
