import { ChangeEvent, useCallback, useEffect } from 'react'

import { InputElementProps } from 'components/selects/types'

const useInput = <Item>({
  onChange,
  value,
  selectedItem,
  itemToString
}: Pick<
  InputElementProps<Item>,
  'onChange' | 'selectedItem' | 'itemToString' | 'value'
>) => {
  useEffect(() => {
    if (!selectedItem) {
      return
    }
    const text = itemToString?.(selectedItem) ?? String(selectedItem)
    if (text === value) {
      return
    }
    onChange(text, true)
  }, [selectedItem])
  // don't update this dependency list (related: job ad positions)

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value)
    },
    [onChange]
  )
  return { onInputChange }
}

export default useInput
