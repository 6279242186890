import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useField } from 'formik'

import { useSkills } from 'hooks/useSkills'

import { Autocomplete } from '../index'

import { ISkillsAutocompleteProps } from './types'

const SkillsAutocomplete = ({
  onChange,
  name,
  ...props
}: ISkillsAutocompleteProps) => {
  const { locale: languageCode } = useRouter()
  const [{ value }] = useField(name)
  const { data, isLoading } = useSkills({ languageCode })

  const options = useMemo(
    () =>
      data?.map((item) => ({
        ...item,
        label: item.value,
        value: item.id ?? ''
      })) ?? [],
    [data]
  )

  const selectedItem = useMemo(() => {
    return options.find((item) => item.id === value)
  }, [options, value])

  return (
    <Autocomplete
      name={name}
      selectedItem={selectedItem}
      items={options}
      itemToString={(x) => x?.label ?? ''}
      isLoading={isLoading}
      onSelect={(skill) => {
        onChange(skill)
      }}
      {...props}
    />
  )
}

export default SkillsAutocomplete
