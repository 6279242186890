import { useTranslation } from 'next-i18next'
import {
  KeyboardEventHandler,
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole
} from '@floating-ui/react'

import { currentSelectedItem, isItemSelectedDefault } from '../utils'

import { IUseAutocompleteProps } from './types'

const useAutocomplete = <Item>({
  items,
  isMulti,
  autoSelect,
  clearAfterSelect,
  defaultValue,
  selectedItem: externalSelectedValue,
  onSelect,
  onChange: onChangeValue,
  itemToString,
  filterFn = (item, inputValue) =>
    itemToString(item)
      .toLowerCase()
      .includes(inputValue?.toLowerCase() ?? ''),
  isItemSelected = isItemSelectedDefault,
  onClean,
  isOpen: isOpenExternal,
  setIsOpen: setIsOpenExternal,
  isFilter = true
}: IUseAutocompleteProps<Item>) => {
  const { t } = useTranslation(['zod', 'actions'])
  const [isOpenInternal, setIsOpenInternal] = useState(false)
  const isOpen = useMemo(
    () => (isOpenExternal === undefined ? isOpenInternal : isOpenExternal),
    [isOpenExternal, isOpenInternal]
  )
  const setIsOpen = useMemo(
    () =>
      setIsOpenExternal === undefined ? setIsOpenInternal : setIsOpenExternal,
    [setIsOpenExternal]
  )

  const [internalSelectedValue, setInternalSelectedValue] =
    useState<Item | null>(null)

  const selectedItem = useMemo(
    () =>
      externalSelectedValue === undefined
        ? internalSelectedValue
        : externalSelectedValue,
    [internalSelectedValue, externalSelectedValue]
  )
  const [inputValue, setInputValue] = useState(
    defaultValue || itemToString(selectedItem ?? null)
  )

  const filteredItems = useMemo(() => {
    if (selectedItem) {
      return items
    }
    if (!filterFn) {
      return items
    }
    return items.filter((x) => filterFn(x, inputValue))
  }, [selectedItem, filterFn, inputValue, items])

  const selectedIndex = useMemo(
    () => currentSelectedItem(filteredItems, selectedItem, isItemSelected),
    [filteredItems, isItemSelected, selectedItem]
  )
  const [activeIndex, setActiveIndex] = useState<number | null>(selectedIndex)

  const listRef = useRef<Array<HTMLElement | null>>([])

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(4),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: '320px'
          })
        },
        padding: 10
      })
    ]
  })

  const role = useRole(context, { role: 'listbox' })
  const click = useClick(context, { keyboardHandlers: false })
  const dismiss = useDismiss(context)
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav, click]
  )

  const onSelectHandler = useCallback(
    (item: Item | null) => {
      onSelect?.(item)

      if (!item) {
        setInternalSelectedValue(null)
      }

      if (externalSelectedValue === undefined && !clearAfterSelect) {
        setInternalSelectedValue(item)
      }
    },
    [clearAfterSelect, externalSelectedValue, onSelect]
  )
  const onChange = useCallback(
    (value: string, isProgrammatically = false) => {
      setInputValue(value)

      onChangeValue?.(value)

      if (isProgrammatically) {
        return
      }

      const option = items.find(
        (item) => itemToString(item).toLowerCase() === value.toLowerCase()
      )

      if (option && autoSelect) {
        onSelectHandler(option)
        setActiveIndex(null)
        setIsOpen(false)
        return
      }

      setIsOpen(true)
      setActiveIndex(0)
      if (selectedItem) {
        onSelectHandler(null)
      }
    },
    [
      onChangeValue,
      items,
      autoSelect,
      setIsOpen,
      selectedItem,
      itemToString,
      onSelectHandler
    ]
  )

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === 'Enter' && activeIndex != null) {
        const item = filteredItems[activeIndex]
        if (item === undefined) {
          return
        }

        onSelectHandler(item)
        setActiveIndex(null)
        setIsOpen(false)
      }
    },
    [activeIndex, filteredItems, onSelectHandler, setIsOpen]
  )

  const onClearSelected = useCallback(() => {
    onSelectHandler(null)
    setActiveIndex(null)
    setInputValue('')
  }, [onSelectHandler])

  const onClearHandler = useCallback(
    (e: MouseEvent) => {
      onClearSelected()
      onClean?.()
      e.stopPropagation()
    },
    [onClean, onClearSelected]
  )

  const itemProps = useCallback(
    (item: Item, index: number) =>
      getItemProps({
        ref: (node) => {
          listRef.current[index] = node
        },
        onClick: () => {
          onSelectHandler(item)
          setIsOpen(false)
          setInputValue(isMulti ? '' : itemToString(item))

          refs.domReference.current?.focus()
        }
      }),
    [
      getItemProps,
      isMulti,
      itemToString,
      onSelectHandler,
      refs.domReference,
      setIsOpen
    ]
  )
  return {
    isOpen,
    items: isFilter ? filteredItems : items,
    refs,
    selectedItem,
    floatingStyles,
    inputValue,
    context,
    activeIndex,
    selectedIndex,
    getReferenceProps,
    getFloatingProps,
    getItemProps: itemProps,
    onChange,
    onKeyDown,
    onClearSelected,
    onClearHandler,
    t
  }
}

export default useAutocomplete
