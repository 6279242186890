import { forwardRef } from 'react'
import { useId } from '@floating-ui/react'
import clsx from 'clsx'

import { IDefaultItemProps } from 'components/selects/types'

const DefaultItem = forwardRef<
  HTMLDivElement,
  IDefaultItemProps<unknown> & React.HTMLProps<HTMLDivElement>
>(function DefaultItem({ children, active, selected, ...rest }, ref) {
  const id = useId()
  return (
    <div
      ref={ref}
      role="option"
      id={id}
      aria-selected={active}
      className={clsx(
        active && 'bg-gray-200',
        selected && 'font-bold',
        'mx-2 flex cursor-default flex-col rounded py-2 px-3'
      )}
      {...rest}
    >
      {children}
    </div>
  )
})

export default DefaultItem
