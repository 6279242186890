import { useTranslation } from 'next-i18next'
import {
  KeyboardEventHandler,
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead
} from '@floating-ui/react'

import { currentSelectedItem, isItemSelectedDefault } from '../utils'

import { IUseSelectProps } from './types'

const useSelect = <Item>({
  items,
  selectedItem,
  itemToString,
  onSelect,
  isItemSelected = isItemSelectedDefault
}: IUseSelectProps<Item>) => {
  const { t } = useTranslation(['zod'])
  const [isOpen, setIsOpen] = useState(false)

  const selectedIndex = useMemo(
    () => currentSelectedItem(items, selectedItem, isItemSelected),
    [items, isItemSelected, selectedItem]
  )
  const [activeIndex, setActiveIndex] = useState<number | null>(selectedIndex)

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
            maxHeight: '320px'
          })
        },
        padding: 10
      })
    ]
  })

  const listRef = useRef<Array<HTMLElement | null>>([])
  const listContentRef = useRef(items.map((x) => itemToString(x)))
  const isTypingRef = useRef(false)

  const click = useClick(context, { event: 'mousedown' })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'listbox' })
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    // This is a large list, allow looping.
    loop: true
  })

  const onSelectByIndex = useCallback(
    (index: number) => onSelect?.(items[index] ?? null),
    [items, onSelect]
  )
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : onSelectByIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping
    }
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [dismiss, role, listNav, typeahead, click]
  )
  const handleSelect = useCallback(
    (item: Item) => {
      onSelect?.(item)
      setIsOpen(false)
    },
    [onSelect]
  )

  const onKeyDown = useCallback<
    (item: Item) => KeyboardEventHandler<HTMLInputElement>
  >(
    (item) => (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        handleSelect(item)
      }

      if (event.key === ' ' && !isTypingRef.current) {
        event.preventDefault()
        handleSelect(item)
      }
    },
    [handleSelect]
  )

  const onClear = useCallback(
    (e: MouseEvent) => {
      onSelect?.(null)
      setActiveIndex(null)
      e.stopPropagation()
    },
    [onSelect]
  )

  const itemProps = useCallback(
    (item: Item, index: number) =>
      getItemProps({
        ref: (node) => {
          listRef.current[index] = node
        },
        onKeyDown: onKeyDown(item),
        onClick: () => {
          handleSelect(item)
        }
      }),
    [getItemProps, handleSelect, onKeyDown]
  )

  return {
    isOpen,
    refs,
    floatingStyles,
    context,
    activeIndex,
    selectedIndex,
    getReferenceProps,
    getFloatingProps,
    getItemProps: itemProps,
    onClear,
    t
  }
}

export default useSelect
