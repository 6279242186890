import React, { useMemo } from 'react'

import { ISelectedItemsProps } from '../types'
import useDataSourcesSummary from '../useDataSourcesSummary'

import Item from './Item'

const SelectedItems = ({
  selectedItems,
  onRemove,
  hide = false
}: ISelectedItemsProps) => {
  const { sourcesToDisplayCount, containerRef } =
    useDataSourcesSummary(selectedItems)

  const displayedItems = useMemo(() => {
    if (
      hide &&
      sourcesToDisplayCount &&
      selectedItems.length >= sourcesToDisplayCount
    ) {
      return selectedItems.slice(0, sourcesToDisplayCount)
    }
    return selectedItems
  }, [hide, sourcesToDisplayCount, selectedItems])

  const additionalItemsCount = useMemo(
    () => selectedItems.length - sourcesToDisplayCount,
    [sourcesToDisplayCount, selectedItems]
  )

  return (
    <div
      className="flex w-full flex-wrap gap-2 overflow-y-auto"
      ref={containerRef}
    >
      {displayedItems.map((item, index) => (
        <Item
          key={index}
          label={item.value}
          onRemove={() => onRemove?.(item.id)}
        />
      ))}
      {hide && sourcesToDisplayCount < selectedItems.length && (
        <Item label={`+${additionalItemsCount}`} />
      )}
    </div>
  )
}

export default SelectedItems
